import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  id: null,
  name: null,
  email: null,
  registered: null,
  paidAccessUntil: null,
  replenishSession: null,
  replenishPaidAccessSuccess: null,
  freeQuestions: 10
}

const userSaveSuccess = (state, action) => {
  return updateObject(state, {
    id: action.id,
    name: action.name,
    email: action.email,
    registered: action.registered,
    paidAccessUntil: action.paidAccessUntil,
    freeQuestions: action.freeQuestions
  })
}

const userUpdateSuccess = (state, action) => {
  return updateObject(state, {
    email: action.email,
    name: action.name
  })
}

const authLogout = state => {
  return updateObject(state, {
    id: null,
    name: null,
    email: null,
    registered: null,
    paidAccessUntil: null,
    replenishSession: null,
    replenishPaidAccessSuccess: null
  })
}

const userReplenishPaidAccessSuccess = (state, action) => {
  return updateObject(state, {
    paidAccessUntil: action.paidAccessUntil,
    replenishPaidAccessSuccess: true,
    replenishSession: null
  })
}

const userReplenishPaidAccessFail = state => {
  return updateObject(state, {
    replenishPaidAccessSuccess: false
  })
}

const userReplenishPaidAccessEraseMessage = state => {
  return updateObject(state, {
    replenishPaidAccessSuccess: null
  })
}

const userReplenishSession = (state, action) => {
  return updateObject(state, {
    replenishSession: {
      id: action.replenishSession,
      days: action.days
    }
  })
}

const userUsedFreeQuestion = state => {
  return updateObject(state, {
    freeQuestions: state.freeQuestions - 1
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SAVE_SUCCESS:
      return userSaveSuccess(state, action)
    case actionTypes.USER_UPDATE_SUCCESS:
      return userUpdateSuccess(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state)
    case actionTypes.RESTORE_STATE:
      return updateObject(state, { ...action.state.user })
    case actionTypes.USER_REPLENISH_SESSION:
      return userReplenishSession(state, action)
    case actionTypes.USER_REPLENISH_PAID_ACCESS_SUCCESS:
      return userReplenishPaidAccessSuccess(state, action)
    case actionTypes.USER_REPLENISH_PAID_ACCESS_FAIL:
      return userReplenishPaidAccessFail(state)
    case actionTypes.USER_REPLENISH_PAID_ACCESS_ERASE_MESSAGE:
      return userReplenishPaidAccessEraseMessage(state)
    case actionTypes.USER_USED_FREE_QUESTION:
      return userUsedFreeQuestion(state)
    default:
      return state
  }
}

export default reducer
