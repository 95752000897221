import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  locked: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIELD_LOCK:
      return updateObject(state, { locked: action.field })
    case actionTypes.FIELD_RELEASE:
      return updateObject(state, { ...initialState })
    case actionTypes.RESTORE_STATE:
      return updateObject(state, { ...action.state.field })
    default:
      return state
  }
}

export default reducer
