export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_ERASE_ERROR = 'AUTH_ERASE_ERROR'

export const USER_SAVE = 'USER_SAVE'
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_REPLENISH_SESSION = 'USER_REPLENISH_SESSION'
export const USER_REPLENISH_PAID_ACCESS_SUCCESS =
  'USER_REPLENISH_PAID_ACCESS_SUCCESS'
export const USER_REPLENISH_PAID_ACCESS_FAIL = 'USER_REPLENISH_PAID_ACCESS_FAIL'
export const USER_REPLENISH_PAID_ACCESS_ERASE_MESSAGE =
  'USER_REPLENISH_PAID_ACCESS_ERASE_MESSAGE'
export const USER_USED_FREE_QUESTION = 'USER_USED_FREE_QUESTION'

export const RESTORE_STATE = 'RESTORE_STATE'

export const EXAM_START = 'EXAM_START'
export const EXAM_STOP = 'EXAM_STOP'
export const EXAM_SUCCESS_MESSAGE = 'EXAM_SUCCESS_MESSAGE'
export const EXAM_ERASE_SUCCESS_MESSAGE = 'EXAM_ERASE_SUCCESS_MESSAGE'
export const EXAM_DISPLAY_CORRECT_ANSWER = 'EXAM_DISPLAY_CORRECT_ANSWER'
export const EXAM_ACCELERATION_CHANGED = 'EXAM_ACCELERATION_CHANGED'
export const QUESTION_ANSWER = 'QUESTION_ANSWER'

export const STATISTICS_RESULT_SAVE = 'STATISTICS_RESULT_SAVE'
export const STATISTICS_RESULT_SAVE_SUCCESS = 'STATISTICS_RESULT_SAVE_SUCCESS'
export const STATISTICS_RESULT_FETCH_SUCCESS = 'STATISTICS_RESULT_FETCH_SUCCESS'
export const STATISTICS_RESULT_SAVING = 'STATISTICS_RESULT_SAVING'

export const FIELD_LOCK = 'FIELD_LOCK'
export const FIELD_RELEASE = 'FIELD_RELEASE'
