import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  exams: [],
  isFetched: false,
  examsCount: 0
}

const resultSaveSuccess = (state, action) => {
  const ex = state.exams.slice()

  ex.unshift(action.result)

  return updateObject(state, {
    exams: ex
  })
}

const statisticsResultFetchSuccess = (state, action) => {
  return updateObject(state, {
    isFetched: true,
    examsCount: action.examsCount
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STATISTICS_RESULT_SAVE_SUCCESS:
      return resultSaveSuccess(state, action)
    case actionTypes.RESTORE_STATE:
      return updateObject(state, { ...action.state.statistics })
    case actionTypes.STATISTICS_RESULT_FETCH_SUCCESS:
      return statisticsResultFetchSuccess(state, action)
    case actionTypes.EXAM_SUCCESS_MESSAGE:
      return updateObject(state, { isFetched: false, exams: [] })
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, { isFetched: false, exams: [], examsCount: 0 })
    default:
      return state
  }
}

export default reducer
