import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  userId: null,
  refreshToken: null,
  expirationDate: null,
  error: null,
  loading: false,
  authRedirectPath: '/pmp-preparation-profile'
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    refreshToken: action.refreshToken,
    expirationDate: action.expirationDate,
    error: null,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const authLogout = state => {
  return updateObject(state, {
    token: null,
    userId: null,
    refreshToken: null,
    expirationDate: null
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return updateObject(state, { error: null, loading: true })
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state)
    case actionTypes.AUTH_FAILED:
      return authFail(state, action)
    case actionTypes.AUTH_ERASE_ERROR:
      return updateObject(state, { error: action.error })
    case actionTypes.RESTORE_STATE:
      return updateObject(state, { ...action.state.auth })
    default:
      return state
  }
}

export default reducer
