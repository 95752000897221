import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  exam: null,
  duration: null,
  deadline: null,
  questionsToAnswer: null,
  questionsAnswered: 0,
  result: [],
  examSuccess: false,
  saving: false,
  displayCorrectAnswer: false,
  acceleration: 0,
  acceleratedDeadline: null
}

const examStart = (state, action) => {
  return updateObject(state, {
    exam: action.exam,
    duration: action.duration,
    deadline: action.deadline,
    questionsToAnswer: action.questionsToAnswer,
    acceleratedDeadline: action.acceleratedDeadline
  })
}

const questionAnswer = (state, action) => {
  let updRes = state.result.slice()

  updRes.push({
    field: action.field,
    [action.questionURL]: action.isCorrect
  })

  return updateObject(state, {
    questionsAnswered: state.questionsAnswered + 1,
    result: updRes
  })
}

const examStop = state => {
  return updateObject(state, {
    exam: null,
    duration: null,
    deadline: null,
    questionsToAnswer: null,
    questionsAnswered: 0,
    result: [],
    examSuccess: false,
    displayCorrectAnswer: false,
    acceleration: 0,
    acceleratedDeadline: null
  })
}

const examDisplayCorrectAnswer = state => {
  const displayCorrectAnswer = state.displayCorrectAnswer

  return updateObject(state, {
    displayCorrectAnswer: !displayCorrectAnswer
  })
}

const examAccelerationChanged = (state, action) => {
  return updateObject(state, {
    acceleration: action.acceleration
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXAM_START:
      return examStart(state, action)
    case actionTypes.EXAM_STOP:
      return examStop(state)
    case actionTypes.RESTORE_STATE:
      return updateObject(state, { ...action.state.exam })
    case actionTypes.QUESTION_ANSWER:
      return questionAnswer(state, action)
    case actionTypes.EXAM_SUCCESS_MESSAGE:
      return updateObject(state, { examSuccess: true, saving: false })
    case actionTypes.EXAM_ERASE_SUCCESS_MESSAGE:
      return updateObject(state, { examSuccess: false })
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, { ...initialState })
    case actionTypes.STATISTICS_RESULT_SAVING:
      return updateObject(state, { saving: true })
    case actionTypes.EXAM_DISPLAY_CORRECT_ANSWER:
      return examDisplayCorrectAnswer(state)
    case actionTypes.EXAM_ACCELERATION_CHANGED:
      return examAccelerationChanged(state, action)
    default:
      return state
  }
}

export default reducer
