import React from 'react'
import { Provider } from 'react-redux'
import {
  createStore as reduxCreateStore,
  compose,
  applyMiddleware,
  combineReducers
} from 'redux'
import thunk from 'redux-thunk'
import authReducer from './src/store/reducers/auth'
import userReducer from './src/store/reducers/user'
import examReducer from './src/store/reducers/exam'
import statisticsReducer from './src/store/reducers/statistics'
import fieldReducer from './src/store/reducers/field'
import { saveState } from './src/utils/connectivity/localStorage'
import _ from 'lodash'

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose

// For development only!
// const composeEnhancers =
//   process.env.NODE_ENV === 'development'
//     ? typeof window !== `undefined`
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//       : null || compose
//     : null

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  exam: examReducer,
  statistics: statisticsReducer,
  field: fieldReducer
})

const store = reduxCreateStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
)

store.subscribe(
  _.throttle(() => {
    saveState({
      auth: store.getState().auth,
      user: store.getState().user,
      exam: store.getState().exam,
      statistics: store.getState().statistics,
      field: store.getState().field
    })
  }, 1000)
)

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}
